import React from 'react';
import TopicsCommonEntries from 'templates/topics-common-entries';
import FamilyCenteredApproachImg from "assets/images/family-centered-approach.jpg";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const data = [
    {
        isHighlightedResource: true,
        isSeries: true,
        title: "Highlighted Resources",
        data: [
            {
                title: "Implementing a Family-Centered Approach",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/drug-testing-brief-1-508.jpg",
                buttonText: "View Series"
            },
        ]
    },
    {
        isHighlightedResource: false,
        title: "Policy and Practice Resources",
        data: [
            {
                title: "Brief 1: Considerations for Developing a Child Welfare Drug Testing Policy and Protocol",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 1: This resource offers key steps for child welfare agency policymakers to consider when developing a drug testing policy for child welfare practice.",
                link: "/",
                fileSize: "11.5 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                fileSize: "10.9 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/drug-testing-brief-2-508.jpg",
                fileSize: "10.9 MB"
            }
        ]
    },
    {
        isHighlightedResource: false,
        title: "Related Online Training, Videos and Webinars",
        data: [
            {
                title: "Brief 1: Considerations for Developing a Child Welfare Drug Testing Policy and Protocol",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 1: This resource offers key steps for child welfare agency policymakers to consider when developing a drug testing policy for child welfare practice.",
                link: "/",
                fileSize: "11.5 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                fileSize: "10.9 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/drug-testing-brief-2-508.jpg",
                fileSize: "10.9 MB"
            }
        ]
    },
    {
        isHighlightedResource: false,
        title: "State and Local Examples",
        data: [
            {
                title: "Brief 1: Considerations for Developing a Child Welfare Drug Testing Policy and Protocol",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 1: This resource offers key steps for child welfare agency policymakers to consider when developing a drug testing policy for child welfare practice.",
                link: "/",
                fileSize: "11.5 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                fileSize: "10.9 MB"
            },
            {
                title: "Brief 2: Drug Testing for Parents Involved in Child Welfare: Three Key Practice Points",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/drug-testing-brief-2-508.jpg",
                fileSize: "10.9 MB"
            }
        ]
    }];


const FamilyCenteredApproach = ({location}) => {

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }

    return (
        <TopicsCommonEntries
            location={location}
            title="Family-Centered Approach"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            topicHeroImage={FamilyCenteredApproachImg}
            color="#f5f5f5"
            pathname={thisLocationPathname}
            data={data}
            >
        </TopicsCommonEntries>
    )};

export default FamilyCenteredApproach;
